
import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import {  getList } from "../../Redux/Departure/actions";
import {  getList as getPackageList} from "../../Redux/Package/actions";
import clean from 'lodash-clean'

function List({ useAuth, getPackageList, packageList }) {
  const dataList = clean(packageList)
  // let tokenData = loginData.data;
  // const [filter_options, setFilterOptions] = useState();

  // useEffect(() => {
  //   if (!packageList.data) {
  //     getPackageList(tokenData);  
  //   }
  //   if (!list.data) {
  //     getList(tokenData);
  //   }
  // }, [packageList.data, list.data]);

  const tableConfig = {
    title: "Trip Departures",
    filterOptionTitle: "Category Type",
    //filterOptions: filter_options,
    applyFilter: false,
    tableColumns: [
      {name: "Title", selector: 'package_title', width: '77%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'type_id',
    searchByColumn: 'package_title',   
    //addPath: "/departure/add",
    updatePath: "/departure/update",
    noDataMessage: "No trip departure exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'package_title',
    // deleteItemsByIdList: (idList) => {
    //   onDelete(idList);
    // },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getPackageList}
      dataToList={dataList}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    packageList: state.packages,
    list: state.departure,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    getPackageList: (tokenData) => dispatch(getPackageList(tokenData)),
    //onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

import {
  DEPARTURE_LIST_LOADING,
  GET_DEPARTURE_LIST,
  GET_DEPARTURE_LIST_ERROR,
  GET_DEPARTURE_BY_ID_LOADING,
  GET_DEPARTURE_BY_ID_SUCCESS,
  GET_DEPARTURE_BY_ID_ERROR,
  DELETEING_DEPARTURE,
  DELETE_DEPARTURE,
  DELETE_DEPARTURE_ERROR,
  GET_DEPARTURE_BY_PACKAGE_ID_LOADING,
  GET_DEPARTURE_BY_PACKAGE_ID_SUCCESS,
  GET_DEPARTURE_BY_PACKAGE_ID_ERROR,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: DEPARTURE_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/travel/departure/all`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_DEPARTURE_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DEPARTURE_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_DEPARTURE_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/travel/departure/single/${id}`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_DEPARTURE_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DEPARTURE_BY_ID_ERROR,
        payload: error.response,
      });
    });
};
export const deleteEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: DELETEING_DEPARTURE,
  });
  let bulk = { bulk: idList };
  await axios
    .post(`${BASE_URL}api/v1/admin/travel/departure/bulk/perma-delete`, bulk, {
      headers: {
        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: DELETE_DEPARTURE,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_DEPARTURE_ERROR,
        payload: error.response,
      });
    });
};
export const getEntityByPackageId =
  (tokenData, package_id) => async (dispatch) => {
    dispatch({
      type: GET_DEPARTURE_BY_PACKAGE_ID_LOADING,
    });
    await axios
      .get(`${BASE_URL}api/v1/admin/travel/departure/package/${package_id}`, {
        headers: {
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: GET_DEPARTURE_BY_PACKAGE_ID_SUCCESS,
          payload: response,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_DEPARTURE_BY_PACKAGE_ID_ERROR,
          payload: error.response,
        });
      });
  };

//   dispatch({
//     type: PERMA_DELETEING_DEPARTURE,
//   });
//   let bulk = {bulk: idList}
//   await axios
//     .post(`${BASE_URL}api/v1/admin/travel/DEPARTURE/bulk/perma-delete`, bulk, {
//       headers: {
//         'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
//       }
//     })
//     .then(function (response) {
//       dispatch({
//         type: PERMA_DELETE_DEPARTURE,
//         payload: response,
//       })
//       message.success(response.data.message);
//     })
//     .catch(function (error) {
//       dispatch({
//         type: PERMA_DELETE_DEPARTURE_ERROR,
//         payload: error.response,
//       })
//       message.error(error.response.data.message);
//     });
// };

// export const restoretDeleteEntity = (idList, history) => async (dispatch) => {
//   dispatch({
//     type: RESTORE_DEPARTURE_LIST_LOADING,
//   });
//   let bulk = {bulk: idList}
//   await axios
//     .post(`${BASE_URL}api/v1/admin/travel/DEPARTURE/bulk/restore`, bulk, {
//       headers: {
//         'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
//       }
//     })
//     .then(function (response) {
//       dispatch({
//         type: RESTORE_DEPARTURE_LIST,
//         payload: response,
//       });
//       message.success(response.data.message);
//         history.push("/packages")
//     })
//     .catch(function (error) {
//       dispatch({
//         type: RESTORE_DEPARTURE_LIST_ERROR,
//         payload: error.response,
//       })
//       message.error(error.response.data.message);
//     });
// };

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Col, Row, Switch, Alert, Spin, TreeSelect } from "antd";
import { connect } from "react-redux";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import DashboardActionBar from "../DashboardActionBar";
import { getList as getCategoryList } from "../../Redux/Category/actions";
const { TreeNode } = TreeSelect;
const FaqsForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,  
  getCategoryList,
  categoryListData,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  let history = useHistory();
  const tokenData = loginData.data;
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const [answer, setAnswer] = useState(
    isUpdateForm && data.dataById && data.dataById.data.type_description
  );
  const [categoriesIDList, setCategoriesIDList] = useState();
  const loading = selectedForm.loading || data.loading || categoryListData.loading;
  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };
  const loadCategory = async () => {
    await getCategoryList(tokenData);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
  }, []);


  useEffect(() => {    
    if (!categoryListData.data) {
      loadCategory();
    }

    if (categoryListData.data) {
      setCategoriesIDList(
        categoryListData.data.data.filter(
          (e) => e.type.category_key === "faqs"
        )
      );
    }

  }, [categoryListData.data])

  let categoriesOptions = categoriesIDList?.map((itm) => {
    const { id, title, children } = itm;
    return {
      value: id,
      label: title,
      ...(children && {
        children: children?.map((child) => {
          const { id, title, children } = child;
          return {
            value: id,
            label: title,
            ...(children && {
              children: children?.map((gChild) => {
                const { id, title } = gChild;
                return {
                  value: id,
                  label: title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });


  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  

  if (isUpdateForm) {
    setFieldsValue({
      question: data.dataById?.data.question,
      url_title: data.dataById?.data.url_title,
      slug: data.dataById?.data.slug,
      title: data.dataById?.data.type_title,
      type_key: data.dataById?.data.type_key,
      status: data.dataById?.data.status == 1 ? true : false,
      answer: data.dataById?.data.answer,
      categories: data.dataById?.data.categories.map((a) => a.id),
      meta_title: data.dataById?.data.meta_title,
      meta_keywords: data.dataById?.data.meta_keywords,
      meta_description: data.dataById?.data.meta_description,
    });
  }

  const faqsAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          params.id
        ).then(() => loadEntityById());
      }
    });
  };

  const addNewEntry = (e) => {
    faqsAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    faqsAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    faqsAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setAnswer("");
  };

  const actionBarOptions = {
    titleToAdd: "Add FAQ",
    titleToUpdate: "Update FAQ",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update FAQ",
    taskCompleteAddMessage: "FAQ is added!",
    taskCompleteUpdateMessage: "FAQ is updated!",
    discardLink: "faqs",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <Form
      form={form}
      size="large"
      layout="vertical"
      className="custom-form"
      wrapperCol={{
        flex: 1,
      }}
    >
      <DashboardActionBar {...{ actionBarOptions }} />

      {selectedForm?.error && (
        <Alert
          className="custom-alert"
          message="Error"
          showIcon
          description={[
            <ul>
              {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                return <li key={idx}>{itm}</li>;
              })}
            </ul>,
          ]}
          type="error"
          closable
        />
      )}

      <Spin spinning={loading} delay={500}>
        <div className="common-module">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                label="Question"
                name="question"
                rules={[
                  {
                    required: true,
                    message: "question is required",
                  },
                  { min: 3, max: 255, message: "Invalid question" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={12}>
                <Form.Item
                  label="Categories"
                  name="categories"
                  rules={[
                    {
                      required: true,
                      message: "Category is required",
                    },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                  >
                    {categoriesOptions?.map((parent) => {
                      const { value, label, children } = parent;
                      return (
                        <TreeNode key={value} value={value} title={label}>
                          {children.length !== 0 &&
                            children?.map((child) => {
                              const { value, label, children } = child;
                              return (
                                <TreeNode
                                  key={value}
                                  value={value}
                                  title={label}
                                >
                                  {children.length !== 0 &&
                                    children?.map((gChild) => {
                                      const { value, label } = gChild;
                                      return (
                                        <TreeNode
                                          key={value}
                                          value={value}
                                          title={label}
                                        />
                                      );
                                    })}
                                </TreeNode>
                              );
                            })}
                        </TreeNode>
                      );
                    })}
                  </TreeSelect>
                </Form.Item>
              </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={12}>
              <div className="switch-group">
                <Form.Item label="Status" valuePropName="checked" name="status">
                  <Switch
                    checkedChildren="Publish"
                    unCheckedChildren="Unpublish"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item label="Answer" name="answer">
                <TextEditor
                  value={answer}
                  initialValue={isUpdateForm && answer}
                  onEditorChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Spin>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryListData: state.category
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: (tokenData) => dispatch(getCategoryList(tokenData))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(FaqsForm);

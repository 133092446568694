import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Col,
  Row,
  Alert,
  InputNumber,
  Upload,
  Switch,
  Spin,
} from "antd";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";

import { getList as getPackageList } from "../../Redux/Package/actions";

//import "antd/dist/antd.css";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import DashboardActionBar from "../DashboardActionBar";
import { useHistory } from "react-router-dom";

const { Option } = Select;

const ItineraryForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getPackageList,
  packageListData,
}) => {
  const history = useHistory();
  const tokenData = loginData.data;
  const [description, setDescription] = useState(
    isUpdateForm && data.dataById?.data.description
  );
  const [hidden, setHidden] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || packageListData.loading;

  const loadPackages = async () => {
    await getPackageList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!packageListData.data) {
      loadPackages();
    }
  }, [packageListData.data]);

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  const packageListOptions = packageListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
    };
  });

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
            description,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
            description,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
            description,
          },
          tokenData,
          params.id
        ).then(() => {
          loadEntityById();
        });
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
  };
  if (isUpdateForm) {
    const itinerariesDetails = data.dataById?.data.details?.map((e) => {
      //   let elevationObj = e.facts?.filter(
      //     (itm) => itm.fact_title === "elevation"
      //   )[0];
      //   let elevation = elevationObj?.fact_value;
      //   let elevationId = elevationObj?.id;

      //   let mealsObj = e.facts?.filter((itm) => itm.fact_title === "meals")[0];
      //   let meals = mealsObj?.fact_value;
      //   let mealsId = mealsObj?.id;

      //   let accommodationObj = e.facts?.filter(
      //     (itm) => itm.fact_title === "accommodation"
      //   )[0];
      //   let accommodation = accommodationObj?.fact_value;
      //   let accommodationId = accommodationObj?.id;

      //   let durationObj = e.facts?.filter(
      //     (itm) => itm.fact_title === "duration"
      //   )[0];
      //   let duration = durationObj?.fact_value;
      //   let durationId = durationObj?.id;

      //   let placeObj = e.facts?.filter((itm) => itm.fact_title === "place")[0];
      //   let place = placeObj?.fact_value;
      //   let placeId = placeObj?.id;

      //   let distanceObj = e.facts?.filter(
      //     (itm) => itm.fact_title === "distance"
      //   )[0];
      //   let distance = distanceObj?.fact_value;
      //   let distanceId = distanceObj?.id;

      return {
        ...e,
        day: e.itinerary_day,
        description: e.itinerary_description,
        title: e.itinerary_title,
        facts: e.facts,
        //     ...(elevation !== "undefined" && {
        //       elevation,
        //       elevationId: elevationId ? elevationId : 0,
        //     }),
        //     ...(meals !== "undefined" && { meals, mealsId: mealsId ? mealsId : 0 }),
        //     ...(accommodation !== "undefined" && {
        //       accommodation,
        //       accommodationId: accommodationId ? accommodationId : 0,
        //     }),
        //     ...(duration !== "undefined" && {
        //       duration,
        //       durationId: durationId ? durationId : 0,
        //     }),
        //     ...(place !== "undefined" && { place, placeId: placeId ? placeId : 0 }),
        //     ...(distance !== "undefined" && {
        //       distance,
        //       distanceId: distanceId ? distanceId : 0,
        //     }),
        //     // elevation: e.facts?.filter((itm) => itm.fact_title === "elevation")[0]?.fact_value,
        //     // elevationId: e.facts?.filter((itm) => itm.fact_title === "elevation")[0]?.id?e.facts?.filter((itm) => itm.fact_title === "elevation")[0]?.id:0,
        //     // meals: e.facts?.filter((itm) => itm.fact_title === "meals")[0]?.fact_value,
        //     // mealsId: e.facts?.filter((itm) => itm.fact_title === "meals")[0]?.id?e.facts?.filter((itm) => itm.fact_title === "meals")[0]?.id:0,
        //     // accommodation: e.facts?.filter((itm) => itm.fact_title === "accommodation")[0]?.fact_value,
        //     // accommodationId: e.facts?.filter((itm) => itm.fact_title === "accommodation")[0]?.id?e.facts?.filter((itm) => itm.fact_title === "accommodation")[0]?.id:0,
        //     // duration: e.facts?.filter((itm) => itm.fact_title === "duration")[0]?.fact_value,
        //     // durationId: e.facts?.filter((itm) => itm.fact_title === "duration")[0]?.id ? e.facts?.filter((itm) => itm.fact_title === "duration")[0]?.id:0,
        //     // place: e.facts?.filter((itm) => itm.fact_title === "place")[0]?.fact_value,
        //     // placeId: e.facts?.filter((itm) => itm.fact_title === "place")[0]?.id? e.facts?.filter((itm) => itm.fact_title === "place")[0]?.id:0,
        //     // distance: e.facts?.filter((itm) => itm.fact_title === "distance")[0]?.fact_value,
        //     // distanceId: e.facts?.filter((itm) => itm.fact_title === "distance")[0]?.id?e.facts?.filter((itm) => itm.fact_title === "distance")[0]?.id:0
      };
    });
    setFieldsValue({
      itinerary_title: data.dataById?.data.itinerary_title,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      package_id: data.dataById?.data.package_id,
      status: data.dataById?.data.status === 1 ? true : false,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      itineraries: itinerariesDetails,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Itinerary",
    titleToUpdate: "Update Itinerary",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Itinerary",
    taskCompleteAddMessage: "Itinerary is added!",
    taskCompleteUpdateMessage: "Itinerary is updated!",
    discardLink: "itineraries",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Title"
                  name="itinerary_title"
                  rules={[
                    {
                      required: true,
                      message: "Itinerary title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Itinerary title" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Url Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 5, max: 255, message: "Invalid package title" },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({ slug: slugify(e.target.value) })
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">
                      Auto Generate from Url Title
                    </span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Select a package"
                  name="package_id"
                  rules={[
                    {
                      required: true,
                      message: "Package field is required",
                    },
                  ]}
                >
                  <Select
                    onSelect={(e) => e !== null && setHidden(true)}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {packageListOptions?.map((itm) => {
                      return (
                        <Option key={itm.value} value={itm.value}>
                          {itm.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={16}>
                <div className="switch-group">
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="status"
                  >
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            {form.getFieldValue("package_id") !== undefined && (
              <Form.List name="itineraries">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.key} className="itineraries">
                        <h6 className="form-label">
                          Day #{index + 1}{" "}
                          {index >= 1 && (
                           <>
                             <span
                              className="btn-delate"
                              onClick={() => remove(field.name)}
                            >
                              Delete
                            </span>
                            {/* <sapn className="arrow up"><i className="bi bi-arrow-up"></i></sapn>
                            <sapn className="arrow down"><i className="bi bi-arrow-down"></i></sapn> */}
                           </>
                          )}
                        </h6>
                        <div class="common-module bg-white">
                          <Row gutter={{ xs: 8, sm: 10, md: 10 }}>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={4}
                              xl={3}
                              hidden
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "itinerary_day"]}
                                initialValue={index + 1}
                                rules={[
                                  {
                                    required: true,
                                    message: "Field is required",
                                  },
                                ]}
                              >
                                <Input placeholder="Day" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} xl={24}>
                              <Form.Item
                                {...field}
                                name={[field.name, "itinerary_title"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Title is required",
                                  },
                                ]}
                              >
                                <Input placeholder="Itinerary title (Outline)*" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "itinerary_description"]}
                              >
                                <TextEditor
                                  simpleTollbar
                                  inline
                                  placeholder="Itinerary description"
                                />
                              </Form.Item>
                              {/* <Col>
                                <Row gutter={{ xs: 8, sm: 10, md: 10 }}>
                                  <Col xs={24} md={12}>
                                    <Form.Item
                                      label="Elevation"
                                      {...field}
                                      name={[field.name, "elevation"]}
                                    >
                                      <InputNumber placeholder="Elevation" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={12}>
                                    <Form.Item
                                      label="Meals"
                                      {...field}
                                      name={[field.name, "meals"]}
                                    >
                                      <Input placeholder="Ex: B.L.D" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={12}>
                                    <Form.Item
                                      label="Accommodation"
                                      {...field}
                                      name={[field.name, "accommodation"]}
                                    >
                                      <Input placeholder="Ex: 5 Star Hotel" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={12}>
                                    <Form.Item
                                      label="Durations"
                                      {...field}
                                      name={[field.name, "duration"]}
                                    >
                                      <Input placeholder="Ex: 5 hrs" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={12}>
                                    <Form.Item
                                      label="Place"
                                      {...field}
                                      name={[field.name, "place"]}
                                    >
                                      <Input placeholder="Ex: Kathmandu" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={12}>
                                    <Form.Item
                                      label="Distance"
                                      {...field}
                                      name={[field.name, "distance"]}
                                    >
                                      <Input placeholder="Ex: 3.5 Km" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col> */}

                              {isUpdateForm && (
                                <>
                                  <Form.Item
                                    hidden
                                    {...field}
                                    label="&nbsp;"
                                    initialValue={0}
                                    name={[field.name, "id"]}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                </>
                              )}

                              <Row
                                gutter={{ xs: 8, sm: 10, md: 10 }}
                                className="trip_facts"
                              >
                                <Form.List
                                  name={[field.name, "facts"]}
                                  initialValue={[
                                    {
                                      fact_title: "elevation",
                                      fact_value: "undefined",
                                    },
                                    {
                                      fact_title: "meals",
                                      fact_value: "B.L.D",
                                    },
                                    {
                                      fact_title: "accommodation",
                                      fact_value: "Guest House",
                                    },
                                    {
                                      fact_title: "durations",
                                      fact_value: "undefined",
                                    },
                                    {
                                      fact_title: "place",
                                      fact_value: "undefined",
                                    },
                                    {
                                      fact_title: "distance",
                                      fact_value: "undefined",
                                    },
                                  ]}
                                >
                                  {(fields, { add, remove }) => (
                                    <>
                                      {fields.map((field, index) => (
                                        <>
                                          <Col xs={24} md={12} hidden>
                                            <Form.Item
                                              {...field}
                                              label={
                                                index === 0
                                                  ? "Elevation"
                                                  : index === 1
                                                  ? "Meals"
                                                  : index === 2
                                                  ? "Accommodation"
                                                  : index === 3
                                                  ? "Durations"
                                                  : index === 4
                                                  ? "Place"
                                                  : index === 5
                                                  ? "Distance"
                                                  : "#Fact " + (index + 1)
                                              }
                                              name={[field.name, "fact_title"]}
                                            >
                                              {index === 0 ? (
                                                <Input
                                                  type="text"
                                                  defaultValue="elevation"
                                                />
                                              ) : index === 1 ? (
                                                <Input
                                                  type="text"
                                                  defaultValue="meals"
                                                />
                                              ) : index === 2 ? (
                                                <Input
                                                  type="text"
                                                  defaultValue="accommodation"
                                                />
                                              ) : index === 3 ? (
                                                <Input
                                                  type="text"
                                                  defaultValue="durations"
                                                />
                                              ) : index === 4 ? (
                                                <Input
                                                  type="text"
                                                  defaultValue="place"
                                                />
                                              ) : (
                                                index === 5 && (
                                                  <Input
                                                    type="text"
                                                    defaultValue="distance"
                                                  />
                                                )
                                              )}
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} md={12}>
                                            <Form.Item
                                              {...field}
                                              label={
                                                index === 0
                                                  ? "Elevation"
                                                  : index === 1
                                                  ? "Meals"
                                                  : index === 2
                                                  ? "Accommodation"
                                                  : index === 3
                                                  ? "Durations"
                                                  : index === 4
                                                  ? "Place"
                                                  : index === 5
                                                  ? "Distance"
                                                  : "#Fact " + (index + 1)
                                              }
                                              name={[field.name, "fact_value"]}
                                            >
                                              {index === 0 ? (
                                                <InputNumber
                                                  placeholder="ex: 1400"
                                                  {...(isUpdateForm && {
                                                    defaultValue: "undefined",
                                                  })}
                                                />
                                              ) : (
                                                <Input
                                                  placeholder="value"
                                                  {...(isUpdateForm && {
                                                    defaultValue:
                                                      index === 1
                                                        ? "B.L.D"
                                                        : index === 2
                                                        ? "Guest House"
                                                        : "undefined",
                                                  })}
                                                />
                                              )}
                                            </Form.Item>

                                            {isUpdateForm && (
                                              <Form.Item
                                                hidden
                                                {...field}
                                                label="&nbsp;"
                                                initialValue={0}
                                                name={[field.name, "id"]}
                                              >
                                                <InputNumber />
                                              </Form.Item>
                                            )}
                                          </Col>
                                        </>
                                      ))}

                                      {fields.length < 6 && (
                                        <Col lg={24}>
                                          <Form.Item>
                                            <button
                                              type="button"
                                              onClick={() => add()}
                                              className="btn btn-outline-secondary rounded-pill font-weight-semibold"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="currentColor"
                                                className="bi bi-plus"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                              </svg>
                                              Add New Facts
                                            </button>
                                          </Form.Item>
                                        </Col>
                                      )}
                                    </>
                                  )}
                                </Form.List>
                              </Row>

                              {isUpdateForm && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, "gallery"]}
                                  className="mb-0"
                                >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture"
                                    multiple
                                    //defaultFileList={[...fileList]}
                                    className="upload-list-inline"
                                  >
                                    <Button icon={<UploadOutlined />}>
                                      Upload Itinerary Gallery
                                    </Button>
                                  </Upload>
                                </Form.Item>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        className="rounded"
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Itinerary
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
          </div>
        </Spin>

        {/* <Spin spinning={loading} delay={500}>
         
        </Spin> */}
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    packageListData: state.packages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageList: (tokenData) => dispatch(getPackageList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryForm);

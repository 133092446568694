import {
  CATEGORY_LIST_LOADING,
  GET_CATEGORY_LIST_ERROR,
  GET_CATEGORY_LIST,
  GET_CATEGORY_BY_ID_LOADING,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_ERROR,
  
  CATEGORY_BY_KEY_TYPE_LOADING,
  GET_CATEGORY_BY_KEY_TYPE,
  GET_CATEGORY_BY_KEY_TYPE_ERROR,

  DELETEING_BULK_CATEGORY,
  DELETE_BULK_CATEGORY,
  DELETE_BULK_CATEGORY_ERROR,
  DELETED_BULK_CATEGORY_LIST_LOADING,
  GET_DELETED_BULK_CATEGORY_LIST,
  GET_DELETED_BULK_CATEGORY_LIST_ERROR,
  RESTORE_BULK_CATEGORY_LIST_LOADING,
  RESTORE_BULK_CATEGORY_LIST,
  RESTORE_BULK_CATEGORY_LIST_ERROR,
  PERMA_DELETEING_BULK_CATEGORY,
  PERMA_DELETE_BULK_CATEGORY,
  PERMA_DELETE_BULK_CATEGORY_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
  dataByKey: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CATEGORY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_CATEGORY_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
      case CATEGORY_BY_KEY_TYPE_LOADING:
        return {
          ...state,
          loading: true,
          error: false,
        };
    case DELETEING_BULK_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_BULK_CATEGORY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RESTORE_BULK_CATEGORY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PERMA_DELETEING_BULK_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
      case GET_CATEGORY_BY_KEY_TYPE:
        return {
          ...state,
          loading: false,
          dataByKey: action.payload.data,
          error: false,
        };
    case DELETE_BULK_CATEGORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_DELETED_BULK_CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case RESTORE_BULK_CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_BULK_CATEGORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_CATEGORY_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_CATEGORY_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
      case GET_CATEGORY_BY_KEY_TYPE_ERROR:
        return {
          ...state,
          dataByKey: undefined,
          loading: false,
          error: action.payload.data,
        };
    case DELETE_BULK_CATEGORY_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_BULK_CATEGORY_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_BULK_CATEGORY_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_BULK_CATEGORY_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;

export const CATEGORY_LIST_LOADING = "CATEGORY_LIST_LOADING";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR";

export const GET_CATEGORY_BY_ID_LOADING = "GET_CATEGORY_BY_ID_LOADING";
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";
export const GET_CATEGORY_BY_ID_ERROR = "GET_CATEGORY_BY_ID_ERROR";

export const CATEGORY_BY_KEY_TYPE_LOADING = "CATEGORY_BY_KEY_TYPE_LOADING";
export const GET_CATEGORY_BY_KEY_TYPE = "GET_CATEGORY_BY_KEY_TYPE";
export const GET_CATEGORY_BY_KEY_TYPE_ERROR = "GET_CATEGORY_BY_KEY_TYPE_ERROR";

export const DELETEING_BULK_CATEGORY = "DELETEING_BULK_CATEGORY";
export const DELETE_BULK_CATEGORY = "DELETE_BULK_CATEGORY";
export const DELETE_BULK_CATEGORY_ERROR = "DELETE_BULK_CATEGORY_ERROR" 

export const DELETED_BULK_CATEGORY_LIST_LOADING = "DELETED_BULK_CATEGORY_LIST_LOADING";
export const GET_DELETED_BULK_CATEGORY_LIST = "GET_DELETED_BULK_CATEGORY_LIST";
export const GET_DELETED_BULK_CATEGORY_LIST_ERROR = "GET_DELETED_BULK_CATEGORY_LIST_ERROR";

export const RESTORE_BULK_CATEGORY_LIST_LOADING = "RESTORE_BULK_CATEGORY_LIST_LOADING";
export const RESTORE_BULK_CATEGORY_LIST = "RESTORE_BULK_CATEGORY_LIST";
export const RESTORE_BULK_CATEGORY_LIST_ERROR = "RESTORE_BULK_CATEGORY_LIST_ERROR";

export const PERMA_DELETEING_BULK_CATEGORY = "PERMA_DELETEING_BULK_CATEGORY";
export const PERMA_DELETE_BULK_CATEGORY = "PERMA_DELETE_BULK_CATEGORY";
export const PERMA_DELETE_BULK_CATEGORY_ERROR = "PERMA_DELETE_BULK_CATEGORY_ERROR" 

import {
  CREATING_DEPARTURE,
  UPDATING_DEPARTURE,
  DEPARTURE_CREATED,
  DEPARTURE_UPDATED,
  ERROR_DEPARTURE_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity = (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_DEPARTURE,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();

    if (data.departures) {
      let departureDate = data.departures.map((item) => item.departure_date);
      let departureCost = data.departures.map((item) => item.departure_cost);
      let departureNote = data.departures.map((itm) => itm.departure_note);
      let departureStatus = data.departures.map((itm) => itm.departure_status);
      for (let i = 0; i < departureDate.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_date]",
          departureDate[i]
        );
      }
      for (let i = 0; i < departureNote.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_note]",
          formData.note
        );
      }
      for (let i = 0; i < departureStatus.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_status]",
          formData.status
        );
      }
      for (let i = 0; i < departureCost.length; i++) {
        webKitFormData.append(
          "departures[" + i + "][departure_cost]",
          departureCost[i]
        );
      }
    }
    ["departures"].forEach((e) => delete data[e]);
   
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/travel/departure/bulk/add`, webKitFormData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: DEPARTURE_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        if (exitNow) {
          history.push("/departures");
        }
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_DEPARTURE_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_DEPARTURE,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    console.log(formData);
    let webKitFormData = new FormData();

   
    ["itineraries"].forEach((e) => delete data[e]);
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });
    webKitFormData.append("_method", "put");

    await axios
      .post(
        `${BASE_URL}api/v1/admin/travel/departure/edit/${id}`,
        webKitFormData,
        {
          headers: {
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: DEPARTURE_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_DEPARTURE_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../Redux/Faqs/actions";
import { getList as getCategoryList } from "../../Redux/Category/actions";

function List({
  useAuth,
  getList,
  list,
  onDelete,
  loginData,
  getCategoryList,
  categoryList,
}) {
  let tokenData = loginData.data;
  const [filter_options, setFilterOptions] = useState();
  const [modify_list, setModifyList] = useState();

  useEffect(() => {
    if (!list.data) {
      getList(tokenData);
    }
    if (!categoryList.data) {
      getCategoryList(tokenData);
    }
    if (categoryList.data) {
      setFilterOptions(
        categoryList?.data?.data
          .filter((e) => e.type?.category_key === "faqs")
          .map((e) => {
            return { value: e.id, text: e.title };
          })
      );
    }
  }, [list.data, categoryList.data]);

  useEffect(() => {
    if (list.data) {
      setModifyList({
        data: {
          status: list?.data.status,
          data: list?.data.data.map((itm) => {
            return {
              ...itm,
              category_id: itm.categories[0].id,
            };
          }),
        },
        loading: list.loading,
        error: list.error,
      });
    }
  }, [list.data]);

  const tableConfig = {
    title: "Faqs",
    filterOptionTitle: "Category",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "Title", selector: "question", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: `category_id`,
    searchByColumn: "question",
    updatePath: "/faq/update",
    addPath: "/faq/add",
    noDataMessage: "No Faqs exists",
    updateLinkColumn: "question",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.faqs,
    categoryList: state.category,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList)),
    getCategoryList: (tokenData) => dispatch(getCategoryList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RoundedSubmitButton } from "../Core/Button";
import {
  Form,
  Input,
  Col,
  Row,
  Switch,
  Alert,
  Spin,
  TreeSelect,
  Select,
  DatePicker,
  InputNumber,
  Tabs,
  Typography,
  Table,
  Radio,
  Modal,
  Space,
  Popconfirm,
  Button,
} from "antd";
import { connect } from "react-redux";
import DashboardActionBar from "../DashboardActionBar";
import { getList as getPackageList } from "../../Redux/Package/actions";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment/moment";
const { TreeNode } = TreeSelect;
const { Option } = Select;

const CollectionCreateForm = ({
  open,
  onCreate,
  onCancel,
  data,
  loading,
  tokenData,
}) => {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  if (data) {
    setFieldsValue({
      departure_date: moment(data.departure_date),
      departure_cost: data.departure_cost,
      departure_note: data.departure_note,
      departure_status: data.departure_status,
      package_id: data.package_id,
      id: data.id,
    });
  }

  return (
    <Modal
      open={open}
      title="Edit Departure Date"
      okText="Save Changes"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCancel();
            onCreate(
              {
                ...values,
                departure_date: moment(values.departure_date).format(
                  "YYYY-MM-DD"
                ),
              },
              tokenData,
              values.id
            );
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <div>
          <Form.Item
            name="departure_date"
            label="Date"
            rules={[
              {
                required: true,
                message: "Date is Required!",
              },
            ]}
          >
            <DatePicker
              format={"YYYY-MM-DD"}
              disabledDate={(current) =>
                current && current < moment().endOf("day")
              }
            />
          </Form.Item>
          <Form.Item
            name="departure_cost"
            label="Cost"
            rules={[
              {
                required: true,
                message: "Price is Required!",
              },
            ]}
          >
            <InputNumber placeholder="Depature cost" />
          </Form.Item>
          <Form.Item name="departure_note" label="Note">
            <Input placeholder="Depature note" />
          </Form.Item>
          <Form.Item
            name="departure_status"
            label="Cost"
            rules={[
              {
                required: true,
                message: "Depature Status is required!",
              },
            ]}
          >
            <Select placeholder="Status">
              <Option value="guaranteed">Guaranteed</Option>
              <Option value="available">Available</Option>
              <Option value="limited">Limited</Option>
              <Option value="closed">Closed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="id" label="id" hidden>
            <InputNumber />
          </Form.Item>
          <Form.Item name="package_id" label="id" hidden>
            <InputNumber />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

const DepartureForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  onDelete,
  isAddForm,
  isUpdateForm,
  params,
  getPackageList,
  getEntityById,
  packageListData,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  let history = useHistory();
  const tokenData = loginData.data;
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const [loading, setLoading] = useState(selectedForm.loading);
  const [tdata, setTData] = useState();
  const [editingKey, setEditingKey] = useState("");
  const [selectedDay, setSelectedDay] = useState();
  const [open, setOpen] = useState(false);
  const [popUpData, setPopUpData] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [defaultPrice, setDefaultPrice] = useState();

  const loadPackages = async () => {
    await getPackageList(tokenData);
  };
  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (!packageListData.data) {
      loadPackages();
    }
    if(packageListData.data){      
      setDefaultPrice(packageListData.data?.data.filter(itm => itm.id == params.id)[0].group_default_price)
    }
  }, [packageListData.data]);

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
  }, [params.id]);

  useEffect(() => {
    if (!loading) {
      setTData(
        data.dataById?.data.map((itm) => {
          return {
            ...itm,
            key: itm.id,
            //departure_date: moment(itm.departure_date).format("DD, MMM, YYYY"),
          };
        })
      );
    }
  }, [loading, data]);

  if (
    selectedForm?.loadedRecently &&
    isAddForm
  ) {
    clearForm();
  }


  const cancel = () => {
    setEditingKey("");
  };

  const handleDelete = (key) => {
    onDelete([key]);
    const newData = tdata.filter((item) => item.key !== key);
    setTData(newData);
  };

  const handleEdit = (key) => {
    setPopUpData(key);
    setOpen(true);
  };

  const columns = [
    {
      title: "Dates",
      dataIndex: "departure_date",
      width: "25%",
      //editable: true,
    },
    {
      title: "Cost",
      dataIndex: "departure_cost",
      width: "15%",
      //editable: true,
    },
    {
      title: "Note",
      dataIndex: "departure_note",
      width: "20%",
      //editable: true,
    },
    {
      title: "Status",
      dataIndex: "departure_status",
      width: "20%",
      //editable: true,
    },

    {
      title: "",
      dataIndex: "operation",
      render: (_, record) =>
        tdata.length >= 1 ? (
          <Space size="middle">
            {/* <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <a className="btn btn-sm btn-danger">
                <i className="bi-trash" />
                Delete
              </a>
            </Popconfirm> */}
            <a
              className="btn btn-sm btn-primary"
              onClick={() => handleEdit(record)}
            >
              <i className="bi-pencil-square" />
              Edit
            </a>
          </Space>
        ) : null,
    },
  ];



  const departures = selectedDay?.map((itm) => {
    return {
      departure_date: moment(itm).format("YYYY-MM-DD"),
      departure_cost: form.getFieldValue('cost'),
      departure_note: form.getFieldValue('note'),
      departure_status: form.getFieldValue('status'),
    };
  });

  const packageListOptions = packageListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
    };
  });

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  if (isUpdateForm) {
    setFieldsValue({
      package_id: params.id
    });
  }
  
  if(defaultPrice){
    form.setFieldValue('cost', defaultPrice)
  }

  const DepartureAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            departures,
          },
          tokenData
        ).then(() => {loadEntityById(); setSelectedDay()});
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            departures,
          },
          tokenData,
          true,
          history
        ).then(() => loadEntityById());
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            departures,
          },
          tokenData,
          params.id
        ).then(() => loadEntityById());
      }
    });
  };

  const addNewEntry = (e) => {
    DepartureAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    DepartureAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    DepartureAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  const actionBarOptions = {
    titleToAdd: "Add Departure",
    titleToUpdate: "Update Departure",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Departure",
    taskCompleteAddMessage: "Departure is added!",
    taskCompleteUpdateMessage: "Departure is updated!",
    discardLink: "departures",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  let disabledDays = tdata?.map(
    (itm) => new Date(moment(itm.departure_date).format("YYYY, MM, DD"))
  );

  // const onCreate = (values) => {
  //   updateEntityInfo(values);
  //   //setOpen(false);
  // };
  const OperationsSlot = {
    left: (
      <a
        className="btn-icon"
        onClick={() => history.push(`/${actionBarOptions.discardLink}`)}
      >
        <i className="bi-arrow-left-circle"></i>
      </a>
    ),
    // right: (

    // )
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedItems(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedItems,
    onChange: onSelectChange,
  };

  const handleBulkDelete = () => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure, you want to delete?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        onDelete(selectedItems)
        setTData(tdata.filter(item => !selectedItems.includes(item.id)))
      },
    });
  };

  const tableSources = tdata&&tdata.sort((a, b) => {
    return Number(new Date(a.departure_date)) - Number(new Date(b.departure_date))
  })

  const tableasyncdata = tableSources?.map((itm) => {
    return{
      ...itm,
      departure_date : moment(itm.departure_date).format('DD MMM, YYYY')
    }
  }) 
  

  return (
    <div className="common-module">
      {selectedForm?.error && (
        <Alert
          className="custom-alert"
          message="Error"
          showIcon
          description={[
            <ul>
              {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                return <li key={idx}>{itm}</li>;
              })}
            </ul>,
          ]}
          type="error"
          closable
        />
      )}
      <Tabs defaultActiveKey="1" tabBarExtraContent={OperationsSlot}>
        <Tabs.TabPane tab="All Dates" key="1">
          <Form form={form} component={false} initialValues={{"cost": defaultPrice}}>
            <CollectionCreateForm
              open={open}
              loading={loading}
              data={popUpData}
              onCreate={updateEntityInfo}
              tokenData={tokenData}
              onCancel={() => {
                setOpen(false);
              }}
            />
            <div className="common-module bg-white data-table mb-0">
              {selectedItems.length >= 1 && (
                <div
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <Button
                    type="primary"
                    danger
                    shape="round"
                    style={{
                      fontWeight: 500,
                      backgroundColor: "#dc3545",
                      fontFamily: "var(--flm-btn-font-family)",
                      border: 0,
                      fontSize: "13px",
                    }}
                    onClick={handleBulkDelete}
                  >
                    <i
                      className="bi-trash"
                      style={{
                        lineHeight: 0,
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    ></i>
                    Delete
                  </Button>
                </div>
              )}
              <Table
                loading={loading}
                dataSource={tableasyncdata&&tableasyncdata}
                columns={columns}
                rowSelection={rowSelection}
                pagination={{
                  onChange: cancel,
                }}
              />
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Add New Departure" key="2">
          <Form
            form={form}
            size="large"
            layout="vertical"
            className="custom-form"
            wrapperCol={{
              flex: 1,
            }}
          >
            <Spin spinning={loading} delay={500}>
              <div className="common-module mb-0" hidden>
                <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                  <Col className="gutter-row" xs={24} sm={12} xl={12}>
                    <Form.Item
                      label="Package"
                      name="package_id"
                      initialValue={params.id}
                      rules={[
                        {
                          required: true,
                          message: "Package is required",
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="common-module bg-white package-departure">
                <h6 className="module-title">
                  Selected Dates (
                  {selectedDay?.length > 0 ? selectedDay?.length : 0})
                </h6>
                <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                  <Col className="gutter-row" xs={24} xl={6} xxl={4}>
                    <div className="date-list">
                      <ul>
                        {selectedDay?.map((itm, idx) => {
                          return (
                            <li key={idx}>
                              {moment(itm).format("DD, MMM, YYYY")}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={24} xl={18} xxl={20}>
                    <DayPicker
                      numberOfMonths={2}
                      showWeekNumber
                      showOutsideDays
                      mode="multiple"
                      onSelect={setSelectedDay}
                      selected={selectedDay}
                      fromDate={new Date()}
                      disabled={disabledDays}
                    />
                    <Form
                      form={form}
                      size="large"
                      layout="vertical"
                      className="custom-form"
                      wrapperCol={{
                        flex: 1,
                      }}
                    >
                      {selectedForm?.error && (
                        <Alert
                          className="custom-alert"
                          message="Error"
                          showIcon
                          description={[
                            <ul>
                              {Object.values(selectedForm?.error?.errors).map(
                                (itm, idx) => {
                                  return <li key={idx}>{itm}</li>;
                                }
                              )}
                            </ul>,
                          ]}
                          type="error"
                          closable
                        />
                      )}


                      <Spin spinning={loading} delay={500}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                          <Col className="gutter-row" xs={24} xl={16}>
                            <Form.Item
                              name="cost"
                              label="Depature cost"
                              rules={[
                                {
                                  required: true,
                                  message: "Departure cost is required",
                                },
                              ]}
                            >
                              <InputNumber
                                value={defaultPrice}
                                placeholder="Depature cost"
                              />
                            </Form.Item>
                            <Form.Item name="note" label="Note">
                              <Input
                                placeholder="Depature note"
                              />
                            </Form.Item>
                            <Form.Item
                              name="status"
                              label="Depature Status"                               
                              initialValue='guaranteed'
                              rules={[
                                {
                                  required: true,
                                  message: "Depature Status is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Depature Status"
                              >
                                <Option value="guaranteed" selected>Guaranteed</Option>
                                <Option value="available">Available</Option>
                                <Option value="limited">Limited</Option>
                                <Option value="closed">Closed</Option>
                              </Select>
                            </Form.Item>
                            <RoundedSubmitButton
                              disabled={loading}
                              className="btn btn-primary"
                              id="add_new"
                              onClick={addNewEntry}
                            >
                              Save Changes
                            </RoundedSubmitButton>
                          </Col>
                        </Row>
                      </Spin>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Spin>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    packageListData: state.packages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageList: (tokenData) => dispatch(getPackageList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartureForm);

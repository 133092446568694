import {
  CREATING_ITINERARY,
  UPDATING_ITINERARY,
  ITINERARY_CREATED,
  ITINERARY_UPDATED,
  ERROR_ITINERARY_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_ITINERARY,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();

    if (data.itineraries) {
      let itineraryDay = data.itineraries.map((item) => item.itinerary_day);
      let itineraryTitle = data.itineraries.map((item) => item.itinerary_title);
      let itineraryDescription = data.itineraries.map(
        (item) => item.itinerary_description
      );
      let itineraryFacts = data.itineraries.map((item) => item.facts);
      //let itineraryElevation = data.itineraries.map((item) => item.elevation);
      //let itineraryMeals = data.itineraries.map((item) => item.meals);
      //let itineraryAccommodation = data.itineraries.map(
      //   (item) => item.accommodation
      // );
      //let itineraryDuration = data.itineraries.map((item) => item.duration);
      //let itineraryPlace = data.itineraries.map((item) => item.place);
      //let itineraryDistance = data.itineraries.map((item) => item.distance);
      for (let i = 0; i < itineraryDay.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_day]`,
          itineraryDay[i]
        );
      }
      for (let i = 0; i < itineraryTitle.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_title]`,
          itineraryTitle[i]
        );
      }
      for (let i = 0; i < itineraryDescription.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_description]`,
          itineraryDescription[i]
        );
      }
      for (let i = 0; i < itineraryDescription.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_description]`,
          itineraryDescription[i]
        );
      }
      for (let i = 0; i < itineraryDay.length; i++) {
        if (itineraryFacts[i] !== undefined) {
          for (let j = 0; j < itineraryFacts[i].length; j++) {
            webKitFormData.append(
              `itineraries[${i}][facts][${j}][fact_title]`,
              itineraryFacts[i][j].fact_title
            );
            webKitFormData.append(
              `itineraries[${i}][facts][${j}][fact_value]`,
              itineraryFacts[i][j].fact_value
            );
          }
        }
      }
      // let elevationIndex = 0;
      // for (let i = 0; i < itineraryDay.length; i++) {
      //   let j = 0;
      //   if (itineraryFacts[i] !== undefined) {
      //     j = itineraryFacts[i].length > 0 ? itineraryFacts[i].length : 0;
      //   }
      //   elevationIndex = j;
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${elevationIndex}][fact_title]`,
      //     "elevation"
      //   );
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${elevationIndex}][fact_value]`,
      //     itineraryElevation[i]
      //   );
      //   let accommodationIndex = elevationIndex + 1;
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${accommodationIndex}][fact_title]`,
      //     "accommodation"
      //   );
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${accommodationIndex}][fact_value]`,
      //     itineraryAccommodation[i]
      //   );
      //   let mealsIndex = accommodationIndex + 1;
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${mealsIndex}][fact_title]`,
      //     "meals"
      //   );
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${mealsIndex}][fact_value]`,
      //     itineraryMeals[i]
      //   );
      //   let durationsIndex = mealsIndex + 1;
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${durationsIndex}][fact_title]`,
      //     "duration"
      //   );
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${durationsIndex}][fact_value]`,
      //     itineraryDuration[i]
      //   );
      //   let placeIndex = durationsIndex + 1;
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${placeIndex}][fact_title]`,
      //     "place"
      //   );
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${placeIndex}][fact_value]`,
      //     itineraryPlace[i]
      //   );

      //   let distanceIndex = placeIndex + 1;
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${distanceIndex}][fact_title]`,
      //     "distance"
      //   );
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${distanceIndex}][fact_value]`,
      //     itineraryDistance[i]
      //   );
      // }
    }
    ["itineraries"].forEach((e) => delete data[e]);
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/travel/itinerary/add`, webKitFormData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: ITINERARY_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        if (exitNow) {
          history.push("/itineraries");
        }
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_ITINERARY_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_ITINERARY,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();

    if (data.itineraries) {
      let itineraryDay = data.itineraries.map((item) => item.itinerary_day);
      let itineraryTitle = data.itineraries.map((item) => item.itinerary_title);
      let ID = data.itineraries.map((item) => item.id);
      let itineraryDescription = data.itineraries.map(
        (item) => item.itinerary_description
      );      
      let itineraryFacts = data.itineraries.map((item) => item.facts);
      for (let i = 0; i < itineraryDay.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_day]`,
          itineraryDay[i]
        );
      }
      for (let i = 0; i < itineraryTitle.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_title]`,
          itineraryTitle[i]
        );
      }
      for (let i = 0; i < ID.length; i++) {
        webKitFormData.append(`itineraries[${i}][id]`, ID[i]);
      }
      for (let i = 0; i < itineraryDescription.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_description]`,
          itineraryDescription[i]
        );
      }
      for (let i = 0; i < itineraryDay.length; i++) {
        if (itineraryFacts[i] !== undefined) {
          for (let j = 0; j < itineraryFacts[i].length; j++) {
            webKitFormData.append(
              `itineraries[${i}][facts][${j}][fact_title]`,
              itineraryFacts[i][j].fact_title
            );
            webKitFormData.append(
              `itineraries[${i}][facts][${j}][fact_value]`,
              itineraryFacts[i][j].fact_value
            );
            webKitFormData.append(
              `itineraries[${i}][facts][${j}][id]`,
              itineraryFacts[i][j].id
            );
          }
        }
      }
    }
    // if (data.itineraries) {
    //   let itineraryDay = data.itineraries.map((item) => item.itinerary_day);
    //   let itineraryTitle = data.itineraries.map((item) => item.itinerary_title);
    //   let itineraryDescription = data.itineraries.map(
    //     (item) => item.itinerary_description
    //   );
    //   let itineraryFacts = data.itineraries.map((item) => item.facts);
    //   let ID = data.itineraries.map((item) => item.id);
    //   let itineraryElevation = data.itineraries.map((item) => item.elevation);
    //   let itineraryElevationId = data.itineraries.map(
    //     (item) => item.elevationId == 0 ? 0 : item.elevationId !== undefined ? item.elevationId : ''
    //   );
    //   let itineraryMeals = data.itineraries.map((item) => item.meals);
    //   let itineraryMealsId = data.itineraries.map((item) => item.mealsId !== undefined ? item.mealsId : 0);
    //   let itineraryAccommodation = data.itineraries.map(
    //     (item) => item.accommodation 
    //   );
    //   let itineraryAccommodationId = data.itineraries.map(
    //     (item) => item.accommodationId !== undefined ? item.accommodationId : 0
    //   );
    //   let itineraryDuration = data.itineraries.map((item) => item.duration);
    //   let itineraryDurationId = data.itineraries.map((item) => item.durationId !== undefined ? item.durationId : 0);
    //   let itineraryPlace = data.itineraries.map((item) => item.place);
    //   let itineraryPlaceId = data.itineraries.map((item) => item.placeId !== undefined ? item.placeId : 0);
    //   let itineraryDistance = data.itineraries.map((item) => item.distance);
    //   let itineraryDistanceId = data.itineraries.map((item) => item.distanceId !== undefined ? item.distanceId : 0);
    //   for (let i = 0; i < itineraryDay.length; i++) {
    //     webKitFormData.append(
    //       `itineraries[${i}][itinerary_day]`,
    //       itineraryDay[i]
    //     );
    //   }
    //   for (let i = 0; i < itineraryTitle.length; i++) {
    //     webKitFormData.append(
    //       `itineraries[${i}][itinerary_title]`,
    //       itineraryTitle[i]
    //     );
    //   }
    //   for (let i = 0; i < itineraryDescription.length; i++) {
    //     webKitFormData.append(
    //       `itineraries[${i}][itinerary_description]`,
    //       itineraryDescription[i]
    //     );
    //   }
    //   for (let i = 0; i < ID.length; i++) {
    //     webKitFormData.append(`itineraries[${i}][id]`, ID[i]);
    //   }
    //   for (let i = 0; i < itineraryDescription.length; i++) {
    //     webKitFormData.append(
    //       `itineraries[${i}][itinerary_description]`,
    //       itineraryDescription[i]
    //     );
    //   }
    //   for (let i = 0; i < itineraryDay.length; i++) {
    //     if (itineraryFacts[i] !== undefined) {
    //       for (let j = 0; j < itineraryFacts[i].length; j++) {
    //         webKitFormData.append(
    //           `itineraries[${i}][facts][${j}][fact_title]`,
    //           itineraryFacts[i][j].fact_title
    //         );
    //         webKitFormData.append(
    //           `itineraries[${i}][facts][${j}][fact_value]`,
    //           itineraryFacts[i][j].fact_value
    //         );
    //         webKitFormData.append(
    //           `itineraries[${i}][facts][${j}][id]`,
    //           itineraryFacts[i][j].id
    //         );
    //       }
    //     }
    //   }
    //   let elevationIndex = 0;
    //   for (let i = 0; i < itineraryDay.length; i++) {
    //     let j = 0;
    //     if (itineraryFacts[i] !== undefined) {
    //       j = itineraryFacts[i].length > 0 ? itineraryFacts[i].length : 0;
    //     }
    //     elevationIndex = j;
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${elevationIndex}][fact_title]`,
    //       "elevation"
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${elevationIndex}][fact_value]`,
    //       itineraryElevation[i]
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${elevationIndex}][id]`,
    //       itineraryElevationId[i]
    //     );
    //     let accommodationIndex = elevationIndex + 1;
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${accommodationIndex}][fact_title]`,
    //       "accommodation"
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${accommodationIndex}][fact_value]`,
    //       itineraryAccommodation[i]
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${accommodationIndex}][id]`,
    //       itineraryAccommodationId[i]
    //     );
    //     let mealsIndex = accommodationIndex + 1;
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${mealsIndex}][fact_title]`,
    //       "meals"
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${mealsIndex}][fact_value]`,
    //       itineraryMeals[i]
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${mealsIndex}][id]`,
    //       itineraryMealsId[i]
    //     );
    //     let durationsIndex = mealsIndex + 1;
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${durationsIndex}][fact_title]`,
    //       "duration"
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${durationsIndex}][fact_value]`,
    //       itineraryDuration[i]
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${durationsIndex}][id]`,
    //       itineraryDurationId[i]
    //     );
    //     let placeIndex = durationsIndex + 1;
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${placeIndex}][fact_title]`,
    //       "place"
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${placeIndex}][fact_value]`,
    //       itineraryPlace[i]
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${placeIndex}][id]`,
    //       itineraryPlaceId[i]
    //     );

    //     let distanceIndex = placeIndex + 1;
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${distanceIndex}][fact_title]`,
    //       "distance"
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${distanceIndex}][fact_value]`,
    //       itineraryDistance[i]
    //     );
    //     webKitFormData.append(
    //       `itineraries[${i}][facts][${distanceIndex}][id]`,
    //       itineraryDistanceId[i]
    //     );
    //   }
    // }
    ["itineraries"].forEach((e) => delete data[e]);
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });
    webKitFormData.append("_method", "put");

    await axios
      .post(
        `${BASE_URL}api/v1/admin/travel/itinerary/edit/${id}`,
        webKitFormData,
        {
          headers: {
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: ITINERARY_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_ITINERARY_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

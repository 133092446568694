import {
  DEPARTURE_LIST_LOADING,
  GET_DEPARTURE_LIST_ERROR,
  GET_DEPARTURE_LIST,
  GET_DEPARTURE_BY_ID_LOADING,
  GET_DEPARTURE_BY_ID_SUCCESS,
  GET_DEPARTURE_BY_ID_ERROR,
  DELETE_DEPARTURE,
  DELETE_DEPARTURE_ERROR,
  DELETEING_DEPARTURE,
  DELETED_DEPARTURE_LIST_LOADING,
  GET_DELETED_DEPARTURE_LIST,
  GET_DELETED_DEPARTURE_LIST_ERROR,
  RESTORE_DEPARTURE_LIST_LOADING,
  RESTORE_DEPARTURE_LIST,
  RESTORE_DEPARTURE_LIST_ERROR,
  PERMA_DELETE_DEPARTURE,
  PERMA_DELETE_DEPARTURE_ERROR,
  PERMA_DELETEING_DEPARTURE,  
  GET_DEPARTURE_BY_PACKAGE_ID_LOADING,
  GET_DEPARTURE_BY_PACKAGE_ID_SUCCESS,
  GET_DEPARTURE_BY_PACKAGE_ID_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEPARTURE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_DEPARTURE_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_DEPARTURE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
      case GET_DEPARTURE_BY_PACKAGE_ID_LOADING:
        return {
          ...state,
          loading: true,
          error: false,
        };
    case DELETEING_DEPARTURE:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case RESTORE_DEPARTURE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case PERMA_DELETEING_DEPARTURE:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_DEPARTURE_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_DEPARTURE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
      case GET_DEPARTURE_BY_PACKAGE_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          dataById: action.payload.data,
          error: false,
        };

    case DELETE_DEPARTURE:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_DELETED_DEPARTURE_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case RESTORE_DEPARTURE_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_DEPARTURE:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_DEPARTURE_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DEPARTURE_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_DEPARTURE_LIST_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_DEPARTURE_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_DEPARTURE_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_DEPARTURE_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
      case GET_DEPARTURE_BY_PACKAGE_ID_ERROR:
        return {
          ...state,
          data: undefined,
          loading: false,
          error: action.payload.data,
        };

    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Switch,
  Alert,
  TreeSelect,
  DatePicker,
  Upload,
  Spin
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";
import moment from "moment";

import { getList as autherList } from "../../Redux/Author/actions";
import { getList as categoryList } from "../../Redux/Category/actions";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import DashboardActionBar from "../DashboardActionBar";
import Tags from "../Core/Tags/Tags";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";

import { BASE_URL } from "../../Redux/urls";

const { Option } = Select;
const { TreeNode } = TreeSelect;


const BlogForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getAuthorList,
  authorListData,
  getCategoryList,
  categoryListData,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [content, setContent] = useState(
    isUpdateForm && data.dataById?.data.content
  );
  const [featured_image, setFeaturedImage] = useState(
    isUpdateForm ? data.dataById?.data.featured?.full_path : null
  );
  const [bannerImage, setBannerImage] = useState();
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;
  const loading =
    selectedForm.loading || categoryListData.loading || authorListData.loading || data.loading

  const loadCategories = async () => {
    await getCategoryList(tokenData);
  };

  const loadAuthors = async () => {
    await getAuthorList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  const childCompRef = React.useRef(null);

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!categoryListData.data) {
      loadCategories();
    }
    if (!authorListData.data) {
      loadAuthors();
    }
  }, [categoryListData.data, authorListData.data]);

  const authorListOptions = authorListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.name,
    };
  });

  let categoriesOptions = categoryListData.data?.data
    .filter((itm) => itm.type.category_key === "blog")
    ?.map((itm) => {
      const { id, title, children } = itm;
      return {
        value: id,
        label: title,
        ...(children && {
          children: children?.map((child) => {
            const { id, title, children } = child;
            return {
              value: id,
              label: title,
              ...(children && {
                children: children?.map((gChild) => {
                  const { id, title } = gChild;
                  return {
                    value: id,
                    label: title,
                  };
                }),
              }),
            };
          }),
        }),
      };
    });

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  if (isUpdateForm) {
    setFieldsValue({
      title: data.dataById?.data.title,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      abstract: data.dataById?.data.abstract,
      blog_date: moment(data.dataById?.data.blog_date),
      publish_status: data.dataById?.data.publish_status === 1 ? true : false,
      featured_status: data.dataById?.data.featured_status === 1 ? true : false,
      content: data.dataById?.data.content,
      authors: data.dataById?.data.authors.map((a) =>  a.id),
      categories: data.dataById?.data.categories.map((a) =>  a.id),
      tags: data.dataById?.data.tags,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      featured_image: data.dataById?.data.featured?.full_path,
      featured_alt: data.dataById?.data.featured?.featured_alt,
      featured_caption: data.dataById?.data.featured?.featured_caption,
      banner_image: data.dataById?.data.banner?.thumb_path,
      banner_alt: data.dataById?.data.featured?.banner_alt,
      banner_caption: data.dataById?.data.featured?.banner_caption,
    });
  }

  const blogAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      values.blog_date = formatDate(values.blog_date);
      values.featured_status = values.featured_status ? 1 : 0;
      values.publish_status = values.publish_status ? 1 : 0;
      values.tags = childCompRef.current.state.tags;

      console.log(values);

      if (action === "add") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData
              );
            });
        } else {
          createEntity(
            {
              ...values,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData
          );
        }
      } else if (action === "addandexit") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData,
                true,
                history
              );
            });
        } else {
          createEntity(
            {
              ...values,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData,
            true,
            history
          );
        }
      } else if (action === "update") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              updateEntityInfo(
                {
                  ...values,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData,
                params.id
              ).then(() => loadEntityById())
            });
        } else {
          updateEntityInfo(
            {
              ...values,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData,
            params.id
          ).then(() => loadEntityById())
        }
      }
    });
  };

  const addNewEntry = (e) => {
    blogAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    blogAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    blogAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setContent("");
    setFeaturedImage("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Blog",
    titleToUpdate: "Update Blog",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Blog",
    taskCompleteAddMessage: "Blog is added!",
    taskCompleteUpdateMessage: "Blog is updated!",
    discardLink: "blogs",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
<Spin spinning={loading} delay={500}>
        <div className="common-module mt-3 bg-white">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                  { min: 5, max: 255, message: "Invalid title" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Url Title"
                name="url_title"
                rules={[
                  {
                    required: true,
                    message: "Url title is required",
                  },
                  { min: 5, max: 255, message: "Invalid Url title" },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldsValue({ slug: slugify(e.target.value) })
                  }
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
              <Form.Item
                label={
                  <>
                    "slug"
                    <span className="ant-form-item-optional">
                      Auto Generate from Url Title
                    </span>
                  </>
                }
                name="slug"
                title="Auto Generate from Url Title"
                rules={[
                  {
                    required: true,
                    message: "Slug is required",
                  },
                ]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item label="Abstract" name="abstract">
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Authors"
                name="authors"
                rules={[
                  {
                    required: true,
                    message: "Author is required",
                  },
                ]}
              >
                <Select mode="multiple">
                  {authorListOptions?.map((itm, idx) => {
                    return (
                      <Option value={itm.value} key={idx}>
                        {itm.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Categories"
                name="categories"
                rules={[
                  {
                    required: true,
                    message: "Category is required",
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                >
                  {categoriesOptions?.map((parent) => {
                    const { value, label, children } = parent;
                    return (
                      <TreeNode key={value} value={value} title={label}>
                        {children?.length !== 0 &&
                          children?.map((child) => {
                            const { value, label, children } = child;
                            return (
                              <TreeNode key={value} value={value} title={label}>
                                {children?.length !== 0 &&
                                  children?.map((gChild) => {
                                    const { value, label } = gChild;
                                    return (
                                      <TreeNode
                                        key={value}
                                        value={value}
                                        title={label}
                                      />
                                    );
                                  })}
                              </TreeNode>
                            );
                          })}
                      </TreeNode>
                    );
                  })}
                </TreeSelect>
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item label="Tags" name="tags">
                <Tags ref={childCompRef} />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Date"
                name="blog_date"
                rules={[
                  {
                    required: true,
                    message: "Date is required",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={16}>
              <div className="switch-group">
                <Form.Item
                  label="Status"
                  valuePropName="checked"
                  name="publish_status"
                >
                  <Switch
                    checkedChildren="Publish"
                    unCheckedChildren="Unpublish"
                  />
                </Form.Item>
                <Form.Item
                  label="Is Featured?"
                  valuePropName="checked"
                  name="featured_status"
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
        <div className="common-module pt-0">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} xl={16}>
              <Form.Item label="Description" name="content">
                <TextEditor
                  value={content}
                  initialValue={isUpdateForm && content}
                  onEditorChange={(e) => {
                    setContent(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={8}>
              <label className="form-label">Meta</label>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "Meta title is required",
                      },
                      { min: 5, max: 255, message: "Invalid meta title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Meta Keywords"
                    name="meta_keywords"
                    rules={[
                      {
                        min: 5,
                        max: 255,
                        message: "Invalid meta keywords",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="mb-0"
                    label="Meta Description"
                    name="meta_description"
                  >
                    <TextArea rows={8} />
                  </Form.Item>
                </div>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} xl={10}>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item>
                    <ImageField
                      title="Avatar"
                      image={
                        featured_image ||
                        (isUpdateForm &&
                          data.dataById?.data.featured?.full_path)
                      }
                      height={process.env.REACT_APP_BLOG_THUMBINAL_HEIGHT}
                      width={process.env.REACT_APP_BLOG_THUMBINAL_WIDTH}
                      setImage={(image_data) => {
                        setFeaturedImage(image_data);
                        setImageCropOpen(true);
                      }}
                    />
                    {featured_image && image_crop_open && ( 
                      <ImageCrop
                        height={process.env.REACT_APP_BLOG_THUMBINAL_HEIGHT}
                        width={process.env.REACT_APP_BLOG_THUMBINAL_WIDTH}
                        image={featured_image}
                        setCroppedImage={setFeaturedImage}
                        setImageCropOpen={setImageCropOpen}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="Alt Text"
                    name="avatar_alt"
                    rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Caption"
                    name="avatar_caption"
                    rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col className="gutter-row " xs={24} xl={14}>
                <div className="common-module bg-white">
                  <h6>Banner Image</h6>
                  <Row gutter={10}>
                    <Col xs={24}>
                      <Form.Item
                        name="bannerImage"
                        style={{ marginBottom: "0" }}
                      >
                        <Upload
                          listType="picture-card"
                          maxCount={1}
                          name="tet"
                          beforeUpload={(file) => {
                            return false;
                          }}
                          onChange={(file) => setBannerImage(file)}
                        >
                          {isUpdateForm &&
                          data.dataById?.data.banner?.full_path &&
                          !bannerImage ? (
                            <img
                              src={
                                BASE_URL + data.dataById?.data.banner?.full_path
                              }
                            />
                          ) : (
                            <UploadOutlined style={{ fontSize: "27px" }} />
                          )}
                        </Upload>
                        <span className="text-muted">
                          Size should be {process.env.REACT_APP_BLOG_BANNER_WIDTH + 'x' + process.env.REACT_APP_BLOG_BANNER_HEIGHT }
                        </span>
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={8}>
                      <Form.Item label="Banner Alt" name="banner_alt">
                        <Input placeholder="Alt Text" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={16}>
                      <Form.Item label="Banner Caption" name="banner_caption">
                        <Input placeholder="banner caption" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
          </Row>
        </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryListData: state.category,
    authorListData: state.author,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: (tokenData) => dispatch(categoryList(tokenData)),
    getAuthorList: (tokenData) => dispatch(autherList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogForm);

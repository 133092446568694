export const DEPARTURE_LIST_LOADING = "DEPARTURE_LIST_LOADING";
export const GET_DEPARTURE_LIST = "GET_DEPARTURE_LIST";
export const GET_DEPARTURE_LIST_ERROR = "GET_DEPARTURE_LIST_ERROR";

export const GET_DEPARTURE_BY_ID_LOADING = "GET_DEPARTURE_BY_ID_LOADING";
export const GET_DEPARTURE_BY_ID_SUCCESS = "GET_DEPARTURE_BY_ID_SUCCESS";
export const GET_DEPARTURE_BY_ID_ERROR = "GET_DEPARTURE_BY_ID_ERROR";

export const GET_DEPARTURE_BY_PACKAGE_ID_LOADING = "GET_DEPARTURE_BY_PACKAGE_ID_LOADING";
export const GET_DEPARTURE_BY_PACKAGE_ID_SUCCESS = "GET_DEPARTURE_BY_PACKAGE_ID_SUCCESS";
export const GET_DEPARTURE_BY_PACKAGE_ID_ERROR = "GET_DEPARTURE_BY_PACKAGE_ID_ERROR";

export const DELETEING_DEPARTURE = "DELETEING_DEPARTURE";
export const DELETE_DEPARTURE = "DELETE_DEPARTURE";
export const DELETE_DEPARTURE_ERROR = "DELETE_DEPARTURE_ERROR" 

export const DELETED_DEPARTURE_LIST_LOADING = "DELETED_DEPARTURE_LIST_LOADING";
export const GET_DELETED_DEPARTURE_LIST = "GET_DELETED_DEPARTURE_LIST";
export const GET_DELETED_DEPARTURE_LIST_ERROR = "GET_DELETED_DEPARTURE_LIST_ERROR";

export const RESTORE_DEPARTURE_LIST_LOADING = "RESTORE_DEPARTURE_LIST_LOADING";
export const RESTORE_DEPARTURE_LIST = "RESTORE_DEPARTURE_LIST";
export const RESTORE_DEPARTURE_LIST_ERROR = "RESTORE_DEPARTURE_LIST_ERROR";

export const PERMA_DELETEING_DEPARTURE = "PERMA_DELETEING_DEPARTURE";
export const PERMA_DELETE_DEPARTURE = "PERMA_DELETE_DEPARTURE";
export const PERMA_DELETE_DEPARTURE_ERROR = "PERMA_DELETE_DEPARTURE_ERROR" 


